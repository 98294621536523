html,
body {
  background: #f4f6f8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.container {
  max-width: 100%;
  /* margin: 0 10px 0 10px; */
  /* max-width: 1200px; */
}
.nav-container {
  margin-left: auto;
  text-align: end;
}
a {
  text-decoration: none;
}
.content {
  flex-grow: 1;
  height: 90vh;
  overflow: auto;
  margin-top: 10px;
}
.homeContainer {
  padding-top: 32px;
  /* padding-bottom: 32px; */
}
